<template>
  <div :class="customClass" :id="elementId" style="position: relative;" fc>
    <div v-if="formObject.summary" ref="summary" style="transform: translateZ(100px); z-index: 999; line-height: 1.5;position: absolute;background-color: rgba(0, 0, 0, 0.50);color: #FFF" :style="{top: top,left:0,right:0,transition:transition}">
      <div class="video-text" style="padding: 4px 10px">{{ formObject.summary }}</div>
    </div>
    <!-- <video style="width: 100%;" ref="video" class="video" :src="formObject.src"></video>-->
    <div class="video-container-wrapper" :class="formObject.controls === false ? 'hidden-controls':''">
      <div class="dplayer-container" ref="video" :id="computedDplayerId"></div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">

// import {mapMutations, mapActions, mapState, mapGetters} from 'vuex';

import DPlayer   from 'dplayer'
import {isEmpty} from 'lodash'

export default {
  // extends   : {},
  mixins    : [],
  components: {},
  name      : 'VideoComponent',
  props     : {
    className : { type: String, default: '' },
    formObject: {
      type   : Object,
      default: () => {
        return {
          id      : 0,
          summary : '',
          src     : '',
          controls: false,
        }
      },
    },
  },
  data() {
    return {
      top  : 0,
      left : 0,
      right: 0,
      // summary      : 'hello world hello world hello world hello world hello world hello world hello world hello world hello world hello world hello world hello world hello world hello world hello world ',
      // summary      : '金泰妍演唱歌曲《Weekend》是韩国女子演唱团体少女时代成员金泰妍演唱的一首歌曲，作为单曲于2021年7月6日发行',
      contentHeight: 0,
      transition   : 'none',
    }
  },
  watch   : {
    'formObject.controls': {
      handler: function (val, oldVal) {
        if (val) {
          let videoElement = this.$refs.video
          // videoElement.setAttribute('class', 'dplayer-hide-controller')

          this.handleMouseover()
        }
        else {
          let videoElement = this.$refs.video
          // videoElement.setAttribute('class', '')
          this.handleMouseout()
        }
      },
      deep   : true,
      // immediate: true,
    },
  },
  computed: {
    computedDplayerId() {
      return `dplayerId_${this._uid}`
    },
    elementId() {return `VideoComponent_${this._uid}`},
    customClass() {return ['VideoComponent', this.className]},
  },
  methods : {
    handleMouseover() {
      console.log('%c【video-list-component:54】-:', 'color:orange', 'call handleMouseenter')
      this.top = 0

    },
    handleMouseout() {
      console.log('%c【video-list-component:50】-:', 'color:orange', 'call handleMouseleave')
      let top = `-${this.contentHeight}`
      console.log('【video-list-component:52】', `top = `, top)
      this.top = top
    },
  },
  created() {
    // document.documentElement.scrollTop = 0
    this.$nextTick(() => {

      ////////////////////////////////////////////////////////////////////////////
      ///////////////// DPlayer //////////////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      const dp = new DPlayer({
        container: document.getElementById(this.computedDplayerId),
        video    : {
          // url: '/resources/videos/TAEYEON 태연 \'Weekend\' MV.mp4',
          url: this.normalizeFileUrl(this.formObject.src),
          pic: isEmpty(this.normalizeImageUrl(this.formObject.pic)) ? undefined : this.normalizeImageUrl(this.formObject.pic),
        },
        preload  : 'auto',
        lang     : 'zh-cn',
      })
      ////////////////////////////////////////////////////////////////////////////
      ///////////////// DPlayer Events ///////////////////////////////////////////
      ////////////////////////////////////////////////////////////////////////////
      dp.on('canplay', () => {
        if (this.formObject.summary) {
          this.$nextTick(() => {
            let summary              = this.$refs.summary
            let summaryComputedStyle = window.getComputedStyle(summary)
            this.contentHeight       = summaryComputedStyle.height
            this.top                 = `-${this.contentHeight}`
            console.log('【video-list-component:104】', `this.top = `, this.top)
            this.transition = 'all .3s'
          })
        }
      })
      // let video = this.$refs.video
      // video.addEventListener('canplay', () => {
      //   this.$nextTick(() => {
      //     let summary              = this.$refs.summary
      //     let summaryComputedStyle = window.getComputedStyle(summary)
      //     this.contentHeight       = summaryComputedStyle.height
      //     this.top                 = `-${this.contentHeight}`
      //     console.log('【video-list-component:104】', `this.top = `, this.top)
      //     this.transition = 'all .3s'
      //   })
      // }, false)
    })
  },
  destroyed() {},
  mounted() {},
}
</script>

<style scoped lang="scss" rel="stylesheet/scss">
.VideoComponent {
  overflow : hidden;
  ///
  //width    : 100%;
  //height   : 100%;
  width    : inherit;
  height   : inherit;
  //border : 10px solid #000;

  .video {
    max-width  : 100%;
    max-height : 100%;
  }

  .video-text {
    text-overflow      : ellipsis;
    display            : -webkit-box;
    -webkit-line-clamp : 1;
    word-break         : break-all; // 注意这个文字多行很重要
    -webkit-box-orient : vertical;
    overflow           : hidden;
  }

  .hidden-controls {
    ::v-deep .dplayer-controller,
    ::v-deep .dplayer-controller-mask {
      opacity   : 0;
      transform : translateY(100%);
    }
  }

  .video-container-wrapper {
    width  : 100%;
    height : 100%;

    .dplayer-container {
      width  : 100%;
      height : 100%;

      ::v-deep .dplayer-video-wrap {
        width  : 100%;
        height : 100%;

        .dplayer-video {
          width  : 100%;
          height : 100%;
        }
      }
    }
  }
}
</style>
